//系统api
import request from "../utils/request";

// 意见反馈
export const addFeedback = (data) =>
  request({
    url: "/applet/feedback/addFeedback",
    method: "post",
    data,
  });

// 消息回复
export const getCommentRemindList = (data) =>
  request({
    url: "/applet/dynamic/getCommentRemindList",
    method: "post",
    data,
  });

// 点赞消息
export const getLikeRemindList = (data) =>
  request({
    url: "/applet/dynamic/getLikeRemindList",
    method: "post",
    data,
  });

// 粉丝关注消息 粉丝列表
export const getFansList = (data) =>
  request({
    url: "/applet/followFans/getFansList",
    method: "post",
    data,
  });

// 关注列表
export const getFollowList = (data) =>
  request({
    url: "/applet/followFans/getFollowList",
    method: "post",
    data,
  });

// 系统消息
export const getLoginUserNotificationList = (data) =>
  request({
    url: "/biz/notificationManagement/getLoginUserNotificationList",
    method: "post",
    data,
  });

// wb登录
export const weiboLogin = (data) =>
  request({
    url: "/applet/weiboLogin",
    method: "post",
    data,
  });

// 添加订单 1金币 2广告 3会员
export const addAccountDetail = (data) =>
  request({
    url: "/applet/accountDetail/addAccountDetail",
    method: "post",
    data,
  });

//统一下单，并组装所需支付参数 微信
export const createAccountDetailOrder = (data) =>
  request({
    url: "/applet/wxpay/createAccountDetailOrder",
    method: "post",
    data,
  });

//统一下单，并组装所需支付参数 支付宝
export const createPay = (data) =>
  request({
    url: "/applet/alipay/createPay",
    method: "post",
    data,
  });

//微信状态轮询
export const getAccountDetail = (data) =>
  request({
    url: "/applet/accountDetail/getAccountDetail",
    method: "post",
    data,
  });

//获取用户未读消息总数
export const getUserUnReadNum = (data) =>
  request({
    url: "/applet/getUserUnReadNum",
    method: "post",
    data,
  });

//用户微信登录注册
export const wxLogin = (data) =>
  request({
    url: "/applet/wxLogin",
    method: "post",
    data,
  });


  
//金币充值配置
export const getRechargeMoneyList = (data) =>
request({
  url: "/applet/rechargeMoney/getRechargeMoneyList",
  method: "post",
  data,
});


//商家转账到零钱
export const transferAccounts = (data) =>
request({
  url: "/applet/wxpay/transferAccounts",
  method: "post",
  data,
});

//单笔转账支付宝
export const transfer = (data) =>
request({
  url: "/applet/alipay/transfer",
  method: "post",
  data,
});

//通过用户id获取聊天信息列表
export const getMessageListByUserId = (data) =>
request({
  url: "/applet/message/getMessageListByUserId",
  method: "post",
  data,
});


//用户明细表
export const getLoginUserAccountDetailList = (data) =>
request({
  url: "/applet/accountDetail/getLoginUserAccountDetailList",
  method: "post",
  data,
});


//通过用户id获取聊天信息列表
export const qqLogin = (data) =>
request({
  url: "/applet/qqLogin",
  method: "post",
  data,
});





//获取广告推广配置列表
export const getAdvertisementConfigurationList = (data) =>
request({
  url: "/biz/advertisement/getAdvertisementConfigurationList",
  method: "post",
  data,
});


//新建一条广告记录
export const addAdvertisementRecord = (data) =>
request({
  url: "/biz/advertisement/addAdvertisementRecord",
  method: "post",
  data,
});


//获取登陆用户广告推广记录列表
export const getLoginUserAdvertisementRecordList = (data) =>
request({
  url: "/biz/advertisement/getLoginUserAdvertisementRecordList",
  method: "post",
  data,
});



//获取网站设置信息
export const getConfig = (data) =>
request({
  url: "/biz/webConfig/getConfig",
  method: "post",
  data,
});
