import axios from "axios";
export default {
  install(Vue) {
    const timestampCC = () => {
      let startTime = localStorage.getItem("oldTime") || 1704942452;
      var timestamp = Date.parse(new Date()) / 1000;
      var date2 = timestamp; //结束时间
      var date3 = (date2 - startTime) * 1000; //时间差的毫秒数
      // console.log(date3);
      //计算出相差天数
      var days = Math.floor(date3 / (24 * 3600 * 1000));
      // console.log(days);
      //计算出小时数
      var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      // console.log(hours);
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      // console.log(minutes);
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      console.log(
        days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒"
      );
      // return (
      //   days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒"
      // );
      // return   days + "天 " + hours + "小时 "
      return minutes;
    };
    Vue.prototype.getIPData = () => {
      if (timestampCC() < 10) {
        console.log("不走");
        return;
      }
      localStorage.setItem("oldTime", Date.parse(new Date()) / 1000);
      axios({
        url: "https://api.songzixian.com/api/ip",
        method: "get",
        params: {
          dataSource: "generic_ip",
        },
      }).then((res) => {
        console.log("全局ip:", res);
        if (res.data.code == 200) {
          localStorage.setItem("ipData", JSON.stringify(res.data.data));
        }
      });
    };
  },
};
