//系统api
import request from "../utils/request";

// 获取手机验证码
export const sendSmsCode = (data) =>
  request({
    url: "/biz/sendSmsCode",
    method: "post",
    data,
  });

// 手机验证码登录
export const phoneSmsCodeLogin = (data) =>
  request({
    url: "/applet/phoneSmsCodeLogin",
    method: "post",
    data,
  });

// 登录用户信息
export const getLoginUserMyMessage = (data) =>
  request({
    url: "/applet/getLoginUserMyMessage",
    method: "post",
    data,
  });

// 修改用户信息
export const updateUserMessage = (data) =>
  request({
    url: "/biz/updateUserMessage",
    method: "post",
    data,
  });

// 关注
export const addFollow = (data) =>
  request({
    url: "/applet/followFans/addFollow",
    method: "post",
    data,
  });

// 取关
export const cancelFollow = (data) =>
  request({
    url: "/applet/followFans/cancelFollow",
    method: "post",
    data,
  });

// 获取任务
export const getMyTaskCenter = (data) =>
  request({
    url: "/biz/userTask/getMyTaskCenter",
    method: "post",
    data,
  });

// 用户签到
export const loginUserSign = (data) =>
  request({
    url: "/biz/loginUserSign",
    method: "post",
    data,
  });


// 修改用户手机号登陆密码
export const setUserPhonePwd = (data) =>
  request({
    url: "/biz/setUserPhonePwd",
    method: "post",
    data,
  });


// 用户名密码登录
export const loginByPhonePwd = (data) =>
  request({
    url: "/applet/loginByPhonePwd",
    method: "post",
    data,
  });


  
// 入驻
export const addSettleIn = (data) =>
request({
  url: "/biz/settleIn/addSettleIn",
  method: "post",
  data,
});

  
// 会员配置
export const getMemberLevelConfigurationList = (data) =>
request({
  url: "/biz/member/getMemberLevelConfigurationList",
  method: "post",
  data,
});


// 获取所有推荐用户列表
export const getAllRecommendPeopleList = (data) =>
request({
  url: "/applet/getAllRecommendPeopleList",
  method: "post",
  data,
});



// 获取登陆用户主页信息
export const getLoginUserHomepage = (data) =>
request({
  url: "/biz/getLoginUserHomepage",
  method: "post",
  data,
});


// 根据id获取用户主页信息
export const getHomepageByUserId = (data) =>
request({
  url: "/biz/getHomepageByUserId",
  method: "post",
  data,
});


// 账号注销
export const deleteUserSelf = (data) =>
request({
  url: "/biz/deleteUserSelf",
  method: "post",
  data,
});
