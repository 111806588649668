//系统api
import request from "../utils/request";

// // 获取广告推广配置列表
// export const getAdvertisementConfigurationList = (data) =>
//   request({
//     url: "/biz/advertisement/getAdvertisementConfigurationList",
//     method: "post",
//     data,
//   });

// // 新建一条广告记录
// export const addAdvertisementRecord = (data) =>
//   request({
//     url: "/biz/advertisement/addAdvertisementRecord",
//     method: "post",
//     data,
//   });

// 删除一条广告推广记录
export const deleteAdvertisementRecord = (data) =>
  request({
    url: "/biz/advertisement/deleteAdvertisementRecord",
    method: "post",
    data,
  });

// 获取广告推广记录详情
export const getAdvertisementRecord = (data) =>
  request({
    url: "/biz/advertisement/getAdvertisementRecord",
    method: "post",
    data,
  });

//获取登陆用户广告推广记录列表
export const getLoginUserAdvertisementRecordList = (data) =>
  request({
    url: "/biz/advertisement/getLoginUserAdvertisementRecordList",
    method: "post",
    data,
  });


  //获取一条推广广告
export const getOne = (data) =>
request({
  url: "/biz/advertisement/getOne",
  method: "post",
  data,
});