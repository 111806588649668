// 统一请求接口函数
import * as webQGS from './webQGS'
import * as user from './user'
import * as article from './article'
import * as system from './system'
import * as adv from './adv'





//统一暴露
export default{
  webQGS,
  user,
  article,
  system,
  adv
}