//系统api
import request from '../utils/request'

// 获取产品/大事记分页列表
export const getProductEventsList = (data) =>request({
  url: '/biz/productEvents/getProductEventsList',
  method: 'post',
  data
})

// 新建一条产品/大事记
export const addProductEvents = (data) =>request({
  url: '/biz/productEvents/addProductEvents',
  method: 'post',
  data
})

// 删除一条产品/大事记
export const deleteProductEvents = (data) =>request({
  url: '/biz/productEvents/deleteProductEvents',
  method: 'post',
  data
})

// 修改一条产品/大事记
export const updateProductEvents = (data) =>request({
  url: '/biz/productEvents/updateProductEvents',
  method: 'post',
  data
})







// 获取新闻动态分页列表
export const getDynamicNewsList = (data) =>request({
  url: '/biz/newsDynamic/getDynamicNewsList',
  method: 'post',
  data
})

// 新建一条新闻动态
export const addDynamicNews = (data) =>request({
  url: '/biz/newsDynamic/addDynamicNews',
  method: 'post',
  data
})

// 删除一条新闻动态
export const deleteDynamicNews = (data) =>request({
  url: '/biz/newsDynamic/deleteDynamicNews',
  method: 'post',
  data
})

// 修改一条新闻动态
export const updateDynamicNews = (data) =>request({
  url: '/biz/newsDynamic/updateDynamicNews',
  method: 'post',
  data
})
