import axios from "axios";
// import {  Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
import router from "../router";
import Message from "ant-design-vue/es/message";

// create an axios instance
const service = axios.create({
  baseURL: "https://api.niwenwd.com", // url = base url + request url
  // baseURL:'http://192.168.0.106:2020',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

// 请求拦截器，携带的token字段
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   // 后台请求携带的key值
    //   config.headers['session_token'] = getToken()
    // }
    config.headers["session_token"] = localStorage.getItem("session_token");
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    // console.log("11111111111122222222222", res);
    if (res.code == 200) {
      return res;
    } else if (res.code == 3000) {
      Message.error(res.msg || "登录已过期！请重新登录！");
      localStorage.removeItem("session_token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("memberMes");
      router.push({
        name: "AnswerIndex",
      });
      return res;
    } else {
      Message.error(res.msg || "系统异常，请稍后再试！");
      // console.log("系统异常，请稍后再试！", res);
      if(res.msg == '动态已删除'){
        router.push({
          name: "AnswerIndex",
        });
      }
      return res;
    }
    // 响应失败
    // if the custom code is not 20000, it is judged as an error.
    // if (res.code != 200) {
    //   // Message({
    //   //   message: res.msg || 'Error',
    //   //   type: 'error',
    //   //   duration: 5 * 1000
    //   // })
    //   // return Promise.reject(new Error(res.message || 'Error'))
    //   Message.error(res.msg || "Error");
    //   localStorage.removeItem("session_token");
    //   localStorage.removeItem("userInfo");
    //   router.push({
    //     name: "AnswerIndex",
    //   });
    //   // console.log('dxsfsdgsdfgd',JSON.parse(localStorage.getItem('userInfo')))
    //   return res;
    // }
    // else if (res.code == 3000) {
    //   Message.error(res.msg || "Error");
    //   localStorage.removeItem("session_token");
    //   localStorage.removeItem("userInfo");
    //   this.$router.push({
    //     name: "AnswerIndex",
    //   });
    //   return res;
    // }
    // else {
    //   // 响应成功
    //   // Message({
    //   //   message: res.msg == 'success' ? '成功' : res.msg,
    //   //   type: 'success',
    //   //   duration: 5 * 1000
    //   // })
    //   return res;
    // }
  },
  (error) => {
    console.log("err" + error); // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error);
  }
);

export default service;
