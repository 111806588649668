import Vue from "vue";
import Router from "vue-router";
import { monitorZoom } from "@/utils/monitorZoom.js";
const m = monitorZoom();
Vue.use(Router);

let originPush = Router.prototype.push; //备份原push方法

Router.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {
    //如果没有传回调函数，手动添加
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
const routers = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "AnswerIndex",
      component: () => import("@/components/MainPage/AnswerIndex"),
      meta: { title: "你问我答" },
    },
    {
      path: "/articleDetails",
      name: "articleDetails",
      component: () => import("@/components/MainPage/articleDetails"),
    },
    {
      path: "/askDetails",
      name: "askDetails",
      component: () => import("@/components/MainPage/askDetails"),
    },
    {
      path: "/articlePublish",
      name: "articlePublish",
      component: () => import("@/components/MainPage/articlePublish"),
      meta: { title: "发布" },
    },
    {
      path: "/videoDetails",
      name: "videoDetails",
      component: () => import("@/components/MainPage/videoDetails"),
    },
    {
      path: "/FConvention",
      name: "FConvention",
      component: () => import("@/components/filePage/FConvention"),
    },
    {
      path: "/privacyPolicy",
      name: "privacyPolicy",
      component: () => import("@/components/filePage/privacyPolicy"),
      meta: { title: "隐私政策" },
    },
    {
      path: "/userAgreement",
      name: "userAgreement",
      component: () => import("@/components/filePage/userAgreement"),
      meta: { title: "用户协议" },
    },
    {
      path: "/broadCast",
      name: "broadCast",
      component: () => import("@/components/filePage/broadCast"),
    },
    {
      path: "/platformSpecifications",
      name: "platformSpecifications",
      component: () => import("@/components/filePage/platformSpecifications"),
      meta: { title: "你问我答平台规范" },
    },
    {
      path: "/noviceHelp",
      name: "noviceHelp",
      component: () => import("@/components/filePage/noviceHelp"),
      meta: { title: "你问我答新手帮助" },
    },
    {
      path: "/otherPeople",
      name: "otherPeople",
      component: () => import("@/components/MainPage/otherPeople"),
      meta: { title: "用户中心" },
    },
    {
      path: "/settleIn",
      name: "settleIn",
      component: () => import("@/components/MainPage/settleIn"),
      meta: { title: "入驻" },
    },
    {
      path: "/updateInfo",
      name: "updateInfo",
      component: () => import("@/components/MainPage/updateInfo"),
      meta: { title: "资料修改" },
    },
    {
      path: "/balanceWithdrawal",
      name: "balanceWithdrawal",
      component: () => import("@/components/MainPage/balance"),
      meta: { title: "余额提现" },
    },
    {
      path: "/advertisementA",
      name: "advertisementA",
      component: () => import("@/components/MainPage/advertisement"),
      meta: { title: "广告合作" },
    },
    {
      path: "/advertisementData",
      name: "advertisementData",
      component: () => import("@/components/MainPage/advertisementData"),
      meta: { title: "我的广告" },
    },
    {
      path: "/infringementA",
      name: "infringementA",
      component: () => import("@/components/MainPage/infringement"),
      meta: { title: "侵权举报" },
    },
    {
      path: "/videoHot",
      name: "videoHot",
      component: () => import("@/components/MainPage/videoHot"),
    },
    {
      path: "/updatePassword",
      name: "updatePassword",
      component: () => import("@/components/MainPage/updatePassword"),
      meta: { title: "密码修改" },
    },
    {
      path: "/searchResult",
      name: "searchResult",
      component: () => import("@/components/MainPage/searchResult"),
    },
    {
      path: "/commentDetails",
      name: "commentDetails",
      component: () => import("@/components/mobilePage/commentDetails"),
    },
    {
      path: "/answerListDetails",
      name: "answerListDetails",
      component: () => import("@/components/mobilePage/answerListDetails"),
    },
    {
      path: "/answerWrite",
      name: "answerWrite",
      component: () => import("@/components/mobilePage/answerWrite"),
    },
    {
      path: "/mobileReport",
      name: "mobileReport",
      component: () => import("@/components/mobilePage/mobileReport"),
    },
    {
      path: "/login",
      name: "loginMob",
      component: () => import("@/components/mobilePage/login"),
    },
    {
      path: "/wbSuccess",
      name: "wbSuccess",
      component: () => import("@/components/oauth/weibo/success"),
    },
    {
      path: "/wbFail",
      name: "wbFail",
      component: () => import("@/components/oauth/weibo/fail"),
    },
    {
      path: "/zfbSuccess",
      name: "zfbSuccess",
      component: () => import("@/components/oauth/zhifubao/success"),
    },
    {
      path: "/qqSuccess",
      name: "qqSuccess",
      component: () => import("@/components/oauth/qq/success"),
    },
    {
      path: "/404",
      name: "PageNotFound",
      component: () => import("@/components/404"),
      hidden: true,
      meta: { title: "404" },
    },
    // 404 page must be placed at the end !!!
    { path: "*", redirect: "/404", hidden: true },
  ],
});

// routers.beforeEach((to, from, next) => {
//   document.title = to.meta.title;
// next();
// });

routers.beforeEach((to, from, next) => {
  console.log("即将跳转到的路由 ------------------------ >  " + to.name, from);
  /* 调整缩放比例 start */
  //针对工控屏调整的缩放比
  if (window.screen.width > 768) {
    if (window.screen.width <= 1024) {
      document.body.style.zoom = 60 / Number(m); //60是对着屏幕具体调的，因屏而异
    } else if (window.screen.width <= 1440) {
      document.body.style.zoom = 74.5 / Number(m);
    } else {
      document.body.style.zoom = 100 / Number(m);
    }
  }
  next();
});
/* 调整缩放比例 end */

routers.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

export default routers;
