//系统api
import request from "../utils/request";

// 创建文章
export const addDynamic = (data) =>
  request({
    url: "/applet/dynamic/addDynamic",
    method: "post",
    data,
  });

  // 删除文章
export const deleteDynamic = (data) =>
request({
  url: "/applet/dynamic/deleteDynamic",
  method: "post",
  data,
});


// 获取用户自己文章
export const getLoginUserDynamicList = (data) =>
  request({
    url: "/applet/dynamic/getLoginUserDynamicList",
    method: "post",
    data,
  });

// 获取全部文章
export const getRecommendDynamicList = (data) =>
  request({
    url: "/applet/dynamic/getRecommendDynamicList",
    method: "post",
    data,
  });

// 获取关注文章
export const getFriendDynamicList = (data) =>
  request({
    url: "/applet/dynamic/getFriendDynamicList",
    method: "post",
    data,
  });

// 获取置顶文章
export const getDynamicTopList = (data) =>
  request({
    url: "/applet/dynamic/getDynamicTopList",
    method: "post",
    data,
  });

// 根据id获取文章
export const getDynamicById = (data) =>
  request({
    url: "/applet/dynamic/getDynamicById",
    method: "post",
    data,
  });

// 评论文章
export const commentDynamic = (data) =>
  request({
    url: "/applet/dynamic/commentDynamic",
    method: "post",
    data,
  });

// 点赞动态评论
export const likeDynamicComment = (data) =>
  request({
    url: "/applet/dynamic/likeDynamicComment",
    method: "post",
    data,
  });
// 取消点赞动态评论
export const cancelLikeDynamicComment = (data) =>
  request({
    url: "/applet/dynamic/cancelLikeDynamicComment",
    method: "post",
    data,
  });

// 点赞动态
export const likeDynamic = (data) =>
  request({
    url: "/applet/dynamic/likeDynamic",
    method: "post",
    data,
  });

// 取消点赞动态
export const cancelLikeDynamic = (data) =>
  request({
    url: "/applet/dynamic/cancelLikeDynamic",
    method: "post",
    data,
  });

// 收藏动态信息
export const collectDynamic = (data) =>
  request({
    url: "/applet/dynamic/collectDynamic",
    method: "post",
    data,
  });

// 取消收藏动态信息
export const cancelCollectDynamic = (data) =>
  request({
    url: "/applet/dynamic/cancelCollectDynamic",
    method: "post",
    data,
  });

// 热门推荐
export const getHotDynamicTop20List = (data) =>
  request({
    url: "/applet/dynamic/getHotDynamicTop20List",
    method: "post",
    data,
  });

// 获取动态视频推荐
export const getRecommendVideoDynamicList = (data) =>
  request({
    url: "/applet/dynamic/getRecommendVideoDynamicList",
    method: "post",
    data,
  });

// 获取热门视频推荐
export const getHotDynamicVideoTop20List = (data) =>
  request({
    url: "/applet/dynamic/getHotDynamicVideoTop20List",
    method: "post",
    data,
  });

// 视频举报
export const addVideoReport = (data) =>
  request({
    url: "/biz/videoReport/addVideoReport",
    method: "post",
    data,
  });

// 文章问答评论举报   1评2文3聊天
export const addReport = (data) =>
  request({
    url: "/biz/report/addReport",
    method: "post",
    data,
  });


// 文章问答侵权 
export const addDynamicComplaint = (data) =>
  request({
    url: "/biz/dynamicComplaint/addDynamicComplaint",
    method: "post",
    data,
  });


  // 文章问答搜索
export const searchDynamic = (data) =>
request({
  url: "/applet/searchDynamic",
  method: "post",
  data,
});


  // 问答用户搜索
  export const searchUser = (data) =>
  request({
    url: "/applet/searchUser",
    method: "post",
    data,
  });
  

    // 新增一条动态推广
    export const addDynamicExtensionRecord = (data) =>
    request({
      url: "/applet/dynamic/addDynamicExtensionRecord",
      method: "post",
      data,
    });
    

    
    // 获取动态推广配置列表
    export const getDynamicExtensionConfigurationList = (data) =>
    request({
      url: "/biz/dynamicExtension/getDynamicExtensionConfigurationList",
      method: "post",
      data,
    });


      
    // 获取动态推广配置列表
    export const getMyDynamicExtensionRecordList = (data) =>
    request({
      url: "/biz/dynamicExtension/getMyDynamicExtensionRecordList",
      method: "post",
      data,
    });


          
    // 获取好友动态视频列表
    export const getFriendDynamicVideoList = (data) =>
    request({
      url: "/applet/dynamic/getFriendDynamicVideoList",
      method: "post",
      data,
    });