<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <!-- <AnswerIndex/> -->
      <router-view v-if="isRouterAlive"></router-view>
    </div>
  </a-config-provider>
</template>

<script>
// import AnswerIndex from './components/MainPage/AnswerIndex.vue'
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  name: "App",
  components: {
    // AnswerIndex
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return { locale: zhCN, isRouterAlive: true };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
</style>
