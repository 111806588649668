import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "./css/global.less";

Vue.use(Antd);

import VueQuillEditor from "vue-quill-editor";
// require styles 引入样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

import Quill from 'quill'
import ImageResize from 'quill-image-resize-module' //调节图片大小
Quill.register('modules/imageResize', ImageResize)


import router from "./router";

import { monitorZoom } from "../src/utils/monitorZoom.js";
const m = monitorZoom();
if (window.screen.width > 768) {
  if (window.screen.width * window.devicePixelRatio >= 3840) {
    document.body.style.zoom = 100 / (Number(m) / 2); // 屏幕为 4k 时
  } else {
    document.body.style.zoom = 100 / Number(m);
  }
}
import axios from "axios";
// 引入api请求接口
import API from "../src/api";

import QC from "qc";
// 挂载在原型上
Vue.prototype.$axios = axios;
Vue.prototype.$API = API;
Vue.prototype.$QC = QC;
Vue.prototype.$baseURL = "https://api.niwenwd.com";
Vue.prototype.$staticURL = "https://static.niwenwd.com/";

import Clipboard from "clipboard";
Vue.prototype.$Clipboard = Clipboard;

import methods from './utils/methods.js'
Vue.use(methods)

Vue.directive("title", {
  inserted: function (el) {
    document.title = el.dataset.title;
  },
});


import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import { Lazyload } from 'vant';

Vue.use(Lazyload);

import { Toast } from 'vant';
Vue.prototype.$toast = Toast;


new Vue({
  render: (h) => h(App),
  router: router,
}).$mount("#app");
